import { useShouldRenderArrayFC } from "@/hooks/useShouldRenderArrayFC";
import HomeHead from "./homeHead/HomeHead";
import ItalianTour from "./italianTour/ItalianTour";
import OurManifest from "./OurManifest";
import Brochures from "./brochures/Brochures";
import TravelExperiences from "./TravelExperiences";
import IwantToVisit from "./IwantToVisit";
import TikTokSection from "./TikTokSection";
import TourReview from "./TourReviews";
import FourSteps from "./FourSteps/FourSteps";
import KeepInTouch from "../common/KeepInTouch";
import useNavBarData from "../../utils/queries/hooks/useNavBarData";
import useHomeData from "../../utils/queries/hooks/useHomeData";
import useVideoReviewData from "../../utils/queries/hooks/useVideoReviewData";
import ItalianStories from "./ItalianStories";
import useGeneralItalianStoriesData from "../../utils/queries/hooks/useGeneralItalianStoriesData";
import SocialProof from "./SocialProof";
// TODO: re-add Social Proof After MVP

const HomeBody = () => {
    const { data: navBarData } = useNavBarData();
    const { data: homeData } = useHomeData();
    const { data: videoReviews } = useVideoReviewData();
    const { data: italianStoriesData } = useGeneralItalianStoriesData();

    return (
        <div className="minWidth100vwFromXL">
            <HomeHead
                smartSearchContent={navBarData!.toursHome}
                destinationToExplore={homeData?.destinationToExplore}
                headImage={homeData!.staticMedia.headImage!}
            />
            {!!homeData?.staticMedia.previewImageVideo &&
                !!homeData?.staticMedia.video && (
                    <OurManifest
                        previewImage={homeData!.staticMedia.previewImageVideo}
                        video={homeData!.staticMedia.video}
                    />
                )}
            {useShouldRenderArrayFC(navBarData?.toursHome) && (
                <ItalianTour toursByType={navBarData!.toursHome} />
            )}
            <TravelExperiences />
            {useShouldRenderArrayFC(homeData?.destinationToExplore) && (
                <IwantToVisit destinations={homeData!.destinationToExplore!} />
            )}
            <Brochures />
            <FourSteps stepperImage={homeData?.staticMedia.stepperImage} />
            {useShouldRenderArrayFC(homeData?.reviews) && (
                <TourReview reviewContents={homeData!.reviews!} />
            )}
            <SocialProof />
            {!!italianStoriesData && (
                <ItalianStories content={italianStoriesData} />
            )}
            {videoReviews && <TikTokSection videoReviews={videoReviews} />}
            <KeepInTouch />
        </div>
    );
};

export default HomeBody;
