import { useQuery } from "@tanstack/react-query";
import { getBrochureData } from "../index";

const useBrochureData = () => {
    return useQuery({
        queryKey: ["brochures"],
        queryFn: () => getBrochureData(),
        refetchOnWindowFocus: false,
    });
};

export default useBrochureData;
