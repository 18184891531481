import {
    dehydrate,
    DehydratedState,
    QueryClient,
    useQuery,
} from "@tanstack/react-query";
import type { GetServerSideProps, NextPage } from "next";
import { ESeoPage } from "@/utils/mockData/metaMocks";
import Layout from "../components/common/Layout";
import HomeBody from "../components/home/HomeBody";
import {
    getBrochureData,
    getHomeData,
    getNavBarData,
    getSeoInfo,
    getVideoReviewsData,
} from "../utils/queries";

const Home: NextPage = () => {
    const { data: metaTags } = useQuery({
        queryKey: ["metaTags", ESeoPage.HOME],
        queryFn: () => getSeoInfo(ESeoPage.HOME),
    });

    return (
        <Layout metaTags={metaTags!}>
            <HomeBody />
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps = async (): Promise<{
    props: {
        dehydratedState: DehydratedState;
    };
}> => {
    const queryClient = new QueryClient();
    await Promise.all([
        await queryClient.prefetchQuery(["navBar"], getNavBarData),
        await queryClient.prefetchQuery(["brochures"], getBrochureData),
        await queryClient.prefetchQuery(["videoReview"], getVideoReviewsData),
        await queryClient.prefetchQuery(["home"], getHomeData),
        await queryClient.prefetchQuery(["metaTags", ESeoPage.HOME], () =>
            getSeoInfo(ESeoPage.HOME)
        ),
    ]);

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
        },
    };
};

export default Home;
