import { FC } from "react";
import styles from "@/styles/home/Home.SmartSearch.module.scss";
import { ISectionTitle } from "../../../models/common/common";
import SectionTitle from "../../common/SectionTitle";

const SmartSeachTitle: FC<{
    title: string;
    description: string;
    defaultSectionTitle: ISectionTitle;
    renderDefault: boolean;
}> = ({ title, description, defaultSectionTitle, renderDefault }) => {
    const sectionTitleContent = {
        title: [
            {
                text: title,
                color: "white",
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
        subTitle: [
            {
                text: description,
                color: "white",
                fontWeight: "fw-bold",
                newLine: false,
            },
        ],
        titleColumnSize: 12,
        spaceBetween: 2,
        alignment: "justify-content-start text-start",
        classNameContainer: "",
        classNameSubTitle: styles.cardSmartSubtitle,
    };

    return (
        <SectionTitle
            sectionTitle={
                renderDefault ? defaultSectionTitle : sectionTitleContent
            }
        />
    );
};

export default SmartSeachTitle;
