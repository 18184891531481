import { MDBCol } from "mdb-react-ui-kit";
import { FC, ReactNode, useEffect, useState } from "react";
import SwiperClass from "swiper";
import { TBreackpointsCarousel } from "../../../models/common/common";
import ButtonBack from "../../common/carousel/ButtonBack";
import ButtonNext from "../../common/carousel/ButtonNext";
import Carousel from "../../common/carousel/Carousel";
import styles from "../../../styles/italian-stories/ItalianStories.module.scss";

const ItalianTourCarousel: FC<{
    children: ReactNode[];
    resetIndex: boolean;
    setResetIndex: (element: boolean) => void;
    containerClassName?: string;
}> = ({ children, resetIndex, setResetIndex, containerClassName = "" }) => {
    const [swiperClass, setSwiperClass] = useState<SwiperClass | null>(null);

    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1,
            spaceBetween: 32,
            autoHeight: true,
        },
        360: {
            slidesPerView: 1.2,
            spaceBetween: 32,
            autoHeight: true,
        },
        540: {
            slidesPerView: 1.5,
            spaceBetween: 32,
            autoHeight: true,
        },
        576: {
            slidesPerView: 1.8,
            spaceBetween: 32,
            autoHeight: true,
        },
        768: {
            slidesPerView: 1,
            width: 256,
            autoHeight: true,
            spaceBetween: 32,
        },
        992: {
            slidesPerView: 2,
            height: 530,
            spaceBetween: 32,
        },
        1200: {
            slidesPerView: 3,
            height: 530,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 530,
        },
        1600: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 530,
        },
        1920: {
            slidesPerView: 4.5,
            spaceBetween: 32,
        },
    };

    useEffect(() => {
        if (resetIndex) {
            setResetIndex(false);
        }
    }, [resetIndex]);

    return (
        <>
            {children.length > 3 && (
                <MDBCol
                    center
                    size={1}
                    className="d-flex justify-content-center"
                >
                    <ButtonBack
                        className="d-none d-md-flex"
                        swiperClass={swiperClass}
                    />
                </MDBCol>
            )}
            <MDBCol
                center
                size={12}
                md={10}
                className={`${containerClassName} ${styles.ContainerCard}`}
            >
                <Carousel
                    visibleSlides={3}
                    setSwiperClass={setSwiperClass}
                    breakpoints={breackPointCarousel}
                >
                    {children}
                </Carousel>
            </MDBCol>
            {children.length > 3 && (
                <MDBCol
                    center
                    size={1}
                    className="d-flex justify-content-center"
                >
                    <ButtonNext
                        className="d-none d-md-flex"
                        swiperClass={swiperClass}
                    />
                </MDBCol>
            )}
        </>
    );
};

export default ItalianTourCarousel;
