import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC } from "react";
import userInterfaceSvg from "../../../utils/svg-icon/userInterface";
import styles from "../../../styles/home/Home.SmartSearch.module.scss";

const SmartNavButton: FC<{
    isSubModal: boolean;
    onBack: () => void;
    onClose: () => void;
}> = ({ isSubModal, onBack, onClose }) => {
    const { ArrowLeftLine, CloseLine } = userInterfaceSvg;

    return (
        <>
            <MDBCol
                size={6}
                lg={2}
                className="pt-3 d-flex justify-content-start justify-content-lg-end align-items-start"
            >
                {isSubModal && (
                    <MDBRow className="d-inline-flex">
                        <MDBBtn
                            className={`${styles.buttonBack} me-0 d-inline-flex align-items-center shadow-0`}
                            onClick={onBack}
                        >
                            <ArrowLeftLine className="align-self-center me-2" />
                            Go back
                        </MDBBtn>
                    </MDBRow>
                )}
            </MDBCol>

            <MDBCol
                size={6}
                lg={1}
                className="d-flex justify-content-end pt-3 pe-0"
            >
                <MDBBtn
                    className={`${
                        isSubModal ? styles.buttonCloseDark : styles.buttonClose
                    } d-flex justify-content-center shadow-0 mt-1`}
                    tag="a"
                    floating
                    onClick={onClose}
                >
                    <CloseLine className="align-self-center" />
                </MDBBtn>
            </MDBCol>
        </>
    );
};

export default SmartNavButton;
