import {
    MDBBtn,
    MDBCard,
    MDBCardOverlay,
    MDBCardText,
    MDBCardTitle,
} from "mdb-react-ui-kit";
import { FC, useState } from "react";
import Image from "next/image";
import { SmartCardContent } from "../../../models/home";
import styles from "../../../styles/home/Home.SmartSearch.module.scss";

const SmartCard: FC<{
    content: SmartCardContent;
}> = ({ content }) => {
    const [error, setError] = useState(false);
    return (
        <MDBCard
            background="dark"
            className={`${styles.cardContainer} text-white rounded-8 h-100`}
            style={{ cursor: "pointer" }}
            onClick={() => content.onClick(content.title, content.index)}
        >
            <div className="position-relative h-100 rounded-8">
                <Image
                    className="object-cover rounded-8"
                    src={
                        error
                            ? "/images/tours/across-italy-reduced.jpg"
                            : content.image
                    }
                    alt="detail-image"
                    fill
                    onError={() => {
                        setError(true);
                    }}
                />
            </div>
            <MDBCardOverlay
                className={`${styles.linearGradient} d-flex flex-column justify-content-end align-items-start`}
            >
                <MDBCardTitle className={styles.title}>
                    {content.title}
                </MDBCardTitle>
                <MDBCardText className={styles.description}>
                    {content.description}
                </MDBCardText>
                <MDBBtn
                    className={`${styles.buttonDetails} my-4 me-auto shadow-0`}
                    color="danger"
                    onClick={() =>
                        content.onClick(content.title, content.index)
                    }
                >
                    View More
                </MDBBtn>
            </MDBCardOverlay>
        </MDBCard>
    );
};

export default SmartCard;
