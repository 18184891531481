import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC, useState } from "react";
import Image from "next/image";
import { RadioButtons } from "../../../models/home";
import styles from "../../../styles/home/Home.SmartSearch.module.scss";
import { useIsMD } from "../../../utils/hooks/useWindowSizes";

const SmartGrid: FC<{
    cities: RadioButtons[];
    onCitySelected: (index: number) => void;
}> = ({ cities, onCitySelected }) => {
    const [imageError, setImageError] = useState<number[]>([]);
    const isMD = useIsMD();

    return (
        <MDBRow className="justify-content-start overflow-auto">
            {cities.map((el, index) => (
                <MDBCol
                    key={el.id}
                    center
                    size={12}
                    lg={3}
                    style={{ aspectRatio: isMD ? "4/1" : "2/1" }}
                    className="p-2"
                    onClick={() => onCitySelected(index)}
                >
                    <div
                        className={`bg-image flex-fill h-100 rounded-6 ${styles.gridCardBgColor}`}
                    >
                        <Image
                            className={`${styles.imageCover} img-fluid w-100 h-100`}
                            src={
                                imageError.includes(index) || !el.value.image
                                    ? "/images/tours/across-italy-reduced.jpg"
                                    : el.value.image
                            }
                            alt="detail-image"
                            fill
                            onError={() => {
                                setImageError(prevState => [
                                    ...prevState,
                                    index,
                                ]);
                            }}
                        />
                        <div
                            className={`${styles.gridLabel} ${
                                styles.gridCard
                            } ${
                                el.value?.image && isMD
                                    ? ""
                                    : styles.gridCardBgColor
                            } mask flex-fill h-100 d-flex justify-content-center align-items-center`}
                        >
                            <p className="m-0">{el.value.title}</p>
                        </div>
                    </div>
                </MDBCol>
            ))}
        </MDBRow>
    );
};

export default SmartGrid;
