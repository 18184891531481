import { MDBBtn } from "mdb-react-ui-kit";
import { FC, useCallback } from "react";
import { ICaroseulButton } from "../../../models/common/common";
import { CouruselStatus } from "../../../models/common/enums";
import styles from "../../../styles/home/Home.ItalianTour.module.scss";

const ItalianTourButtons: FC<{
    carouselButtons: ICaroseulButton[];
    status: CouruselStatus;
    setStatus: (element: CouruselStatus) => void;
    setResetIndex: (element: boolean) => void;
}> = ({ carouselButtons, status, setResetIndex, setStatus }) => {
    const resetCarousel = useCallback(
        (element: CouruselStatus) => {
            setResetIndex(true);
            setStatus(element);
        },
        [carouselButtons]
    );

    return (
        <div className={styles.italianTourBtns}>
            {carouselButtons.map(tab => (
                <MDBBtn
                    key={tab.id}
                    className={`${
                        status === tab.status
                            ? styles.buttonSelected
                            : styles.buttonNotSelected
                    } ${styles.btn} shadow-0 mx-2`}
                    onClick={() => resetCarousel(tab.status)}
                >
                    {tab.name}
                </MDBBtn>
            ))}
        </div>
    );
};

export default ItalianTourButtons;
