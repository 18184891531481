import {
    MDBCard,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBRipple,
    MDBRow,
} from "mdb-react-ui-kit";
import { FC, useState } from "react";
import Image from "next/image";
import { DestinationToExploreEntity } from "../../../models/home";
import styles from "../../../styles/home/Home.CardVisit.module.scss";
import contactsSvg from "../../../utils/svg-icon/contacts";

const CardVisit: FC<{
    content: DestinationToExploreEntity;
    onClick?: () => void;
}> = ({ content, onClick }) => {
    const [error, setError] = useState(false);

    const { MapsMarkerLine } = contactsSvg;

    return (
        <MDBCard className={`${styles.cardVisit} shadow-0 flex-fill`}>
            <MDBRipple role="button" onClick={onClick}>
                <div
                    className={`${styles.cardImage} position-relative h-100 mb-4`}
                >
                    <Image
                        className={`${styles.cardImage} object-cover h-100`}
                        src={
                            error
                                ? "/images/tours/across-italy-reduced.jpg"
                                : content.image
                        }
                        alt="avatar-image"
                        fill
                        onError={() => {
                            setError(true);
                        }}
                    />
                </div>
                <div className={styles.cardVisitFooter}>
                    <MDBCardTitle className={`${styles.textTitle} mb-2`}>
                        {content.title}
                    </MDBCardTitle>
                    <MDBRow>
                        <MDBCol className={`${styles.ico}`} size={1}>
                            <MapsMarkerLine />
                        </MDBCol>
                        <MDBCol size={10} lg={11} center>
                            <MDBCardText className={`${styles.textSubTitle}`}>
                                {content.searchableLabel}
                            </MDBCardText>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBRipple>
        </MDBCard>
    );
};

CardVisit.defaultProps = {
    onClick: undefined,
};

export default CardVisit;
