import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import { FC } from "react";
import { ISectionTitle } from "@/models/common/common";
import { useIsMD } from "@/hooks/useWindowSizes";
import { mainSubTitle, mainTitle } from "@/utils/mockData/smartSearch";
import SmartSeachTitle from "./SmartSearchTitle";
import SmartNavButton from "./SmartNavButton";

const SmartSearchModalHead: FC<{
    isMain: boolean;
    headSection: {
        title: string;
        description: string;
        background: string;
    };
    onBack: () => void;
    toggleShow: () => void;
    className?: string;
}> = ({ isMain, headSection, onBack, toggleShow, className = "" }) => {
    const isMD = useIsMD();

    const title: ISectionTitle = {
        title: mainTitle,
        subTitle: mainSubTitle,
        titleColumnSize: 12,
        spaceBetween: 2,
        alignment: "justify-content-start text-start",
    };

    const renderSmartNavBtn = (
        <SmartNavButton
            isSubModal={!isMain}
            onBack={onBack}
            onClose={toggleShow}
        />
    );

    return (
        <MDBRow className={`${className} w-100`}>
            {isMD && renderSmartNavBtn}
            <MDBCol size={12} lg={9} className="mt-3 mt-lg-0">
                <SmartSeachTitle
                    title={headSection.title}
                    description={headSection.description}
                    defaultSectionTitle={title}
                    renderDefault={isMain}
                />
            </MDBCol>
            {!isMD && renderSmartNavBtn}
        </MDBRow>
    );
};

export default SmartSearchModalHead;
