import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { LayoutType, TourType } from "../../../models/common/enums";
import {
    DestinationToExploreEntity,
    ISmartCard,
    ToursByTourTypeEntity,
} from "../../../models/home";
import { randomUid } from "../../../utils/randomIds";
import SmartGrid from "./SmartGrid";
import SmartSearchCarousels from "./SmartSearchCarousels";
import SmartSearchSelection from "./SmartSearchSelection";
import SmartTourType from "./SmartTourType";

const SmartSearchModalBody: FC<{
    layoutType: LayoutType;
    setLayoutType: Dispatch<SetStateAction<LayoutType>>;
    content: ToursByTourTypeEntity[];
    destinationToExplore: DestinationToExploreEntity[];
    setSelectedTourTypeIndex: Dispatch<SetStateAction<number>>;
    selectedTourType: string;
    setSelectedTourType: Dispatch<SetStateAction<string>>;
    selectedCity: number;
    setSelectedCity: Dispatch<SetStateAction<number>>;
}> = ({
    content,
    destinationToExplore,
    setSelectedTourTypeIndex,
    layoutType,
    setLayoutType,
    selectedTourType,
    setSelectedTourType,
    selectedCity,
    setSelectedCity,
}) => {
    const regions = useMemo(() => {
        const results: { region: string; cities: string[] }[] = [];
        destinationToExplore.forEach(element => {
            const foundElement = results.findIndex(
                el => el.region === element.region
            );
            if (foundElement === -1) {
                results.push({
                    region: element.region,
                    cities: [element.city],
                });
            } else {
                results.at(foundElement)?.cities.push(element.city);
            }
        });
        return results;
    }, [destinationToExplore]);

    const isMain = layoutType === LayoutType.MAIN;
    const isSubModal = layoutType === LayoutType.SUBMODAL;
    const isSubSubModal = layoutType === LayoutType.SUBSUBMODAL;

    const toggleShowSubModal = (el: string, index: number) => {
        setSelectedTourTypeIndex(index);
        setSelectedTourType(
            TourType[
                (Object.keys(TourType).find(
                    key =>
                        key.toLowerCase() ===
                        el.toLowerCase().replace(/\s+/g, "")
                ) ?? "ACROSSITALY") as keyof typeof TourType
            ]
        );
        setLayoutType(LayoutType.SUBMODAL);
    };

    const onCitySelected = (selectedCitiesIndex: number) => {
        setSelectedCity(selectedCitiesIndex);
        setLayoutType(LayoutType.SUBSUBMODAL);
    };

    const cardContent: ISmartCard[] = useMemo(
        () =>
            content.map((el, idx) => ({
                id: randomUid(),
                value: {
                    index: idx,
                    image: el.image,
                    title: el.tourType,
                    description: el.body,
                    onClick: toggleShowSubModal,
                },
            })),
        [content]
    );

    const cardContentFiltered = useMemo((): ISmartCard[] => {
        const tourTypeArray = Object.values(TourType) as string[];

        return tourTypeArray.reduce((acc: ISmartCard[], el: string) => {
            const elementFound = cardContent.find(
                card => card.value.title.toLowerCase() === el.toLowerCase()
            );
            acc.push(elementFound as ISmartCard);
            return acc;
        }, []);
    }, [cardContent]);

    const radioValue = useMemo(() => {
        if (selectedTourType === TourType.INTOTHEREGION) {
            return regions.map(el => {
                return {
                    id: randomUid(),
                    value: {
                        image: undefined,
                        title: el.region,
                        description: undefined,
                    },
                };
            });
        }

        return destinationToExplore.map(el => {
            return {
                id: randomUid(),
                value: {
                    image: el.image,
                    title: el.city,
                    description: el.body,
                },
            };
        });
    }, [content, selectedTourType]);

    const toursByType = useCallback(() => {
        return (
            content.find(
                type =>
                    type.tourType.toLowerCase() ===
                    selectedTourType.toLowerCase()
            )?.tours ?? []
        );
    }, [content, selectedTourType]);

    return (
        <div className="w-100 h-100 d-flex flex-column">
            {isMain && <SmartTourType content={cardContentFiltered} />}
            {isSubModal && selectedTourType === TourType.DOWNTHECITY && (
                <SmartGrid
                    cities={radioValue}
                    onCitySelected={onCitySelected}
                />
            )}
            {isSubModal && selectedTourType !== TourType.DOWNTHECITY && (
                <SmartSearchSelection
                    cities={radioValue}
                    regions={regions}
                    selectedTourType={selectedTourType}
                    toursByType={toursByType()}
                />
            )}
            {isSubSubModal && (
                <SmartSearchCarousels
                    showOtherResults
                    selectedCities={[radioValue[selectedCity].value.title]}
                    tours={toursByType()}
                />
            )}
        </div>
    );
};

export default SmartSearchModalBody;
