import { SectionsTitleText } from "../../models/common/common";
import colors from "../../styles/colors.module.scss";

export const mainTitle: SectionsTitleText[] = [
    {
        text: "How much of Italy",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "are you ready to see?",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const mainSubTitle: SectionsTitleText[] = [
    {
        text: "Choose the perfect tour for you.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
