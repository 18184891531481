import Italy from "../../public/custom-icons/home-custom/italy.svg";
import ItalyHand from "../../public/custom-icons/home-custom/italy-hand.svg";
import Sun from "../../public/custom-icons/home-custom/sun.svg";

const locationSvg = {
    Italy,
    ItalyHand,
    Sun,
};

export default locationSvg;
