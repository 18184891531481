import {
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBRow,
} from "mdb-react-ui-kit";
import { FC, useState } from "react";
import Image from "next/image";
import styles from "../../../styles/home/Home.CardStories.module.scss";
import contactsSvg from "../../../utils/svg-icon/contacts";
import { ItalianStoriesResponseTo } from "../../../models/italianStory";
import Tag from "../../common/Tag";

const CardStories: FC<{
    content: ItalianStoriesResponseTo;
    onClick: () => void;
}> = ({ content, onClick }) => {
    const { ClockLine } = contactsSvg;
    const [previewImageError, setPreviewImageError] = useState(false);

    return (
        <MDBCard
            role="button"
            className={`${styles.containerCard} m-4 shadow-0 user-select-none`}
            onClick={onClick}
        >
            <div className={`${styles.containerImage} position-relative`}>
                <Image
                    className={`${styles.containerImage} object-cover h-100`}
                    src={
                        previewImageError
                            ? "/images/tours/across-italy-reduced.jpg"
                            : content.coverImage
                    }
                    alt="detail-image"
                    fill
                    onError={() => {
                        setPreviewImageError(true);
                    }}
                />
            </div>
            <div className="p-4" style={{ minHeight: "18rem" }}>
                <MDBRow className="px-2">
                    {!!content.tags &&
                        content.tags.map(tag => (
                            <Tag
                                key={tag.label}
                                title={tag.label}
                                color={tag.color}
                                className={`${styles.tag} mx-2 my-1`}
                            />
                        ))}
                </MDBRow>

                <MDBCardBody className="d-flex flex-column justify-content-between py-3 px-0">
                    <MDBCardTitle className={`${styles.textTitle}`}>
                        {content.title}
                    </MDBCardTitle>
                    <MDBCardText className="d-flex">
                        <ClockLine />
                        <span className={`${styles.textSubTitle} ps-2`}>
                            Estimated read time: {content.time} minutes
                        </span>
                    </MDBCardText>
                </MDBCardBody>
                <MDBCardBody
                    className={`${styles.textCardFooter} d-flex justify-content-between py-4 px-0 pt-2`}
                >
                    <div className="d-flex">
                        <div className={`${styles.cardDataContainer}`}>
                            {content.avatar && (
                                <div
                                    className={styles.cardImageProfileContainer}
                                >
                                    <Image
                                        src={
                                            content.avatar ? content.avatar : ""
                                        }
                                        className={styles.imageProfile}
                                        style={{
                                            borderColor: content.colorAvatar
                                                ? content.colorAvatar
                                                : "#00ff2a",
                                        }}
                                        alt="user-image"
                                        width={40}
                                        height={40}
                                    />
                                </div>
                            )}
                            <div className="ms-1">
                                <span className={styles.cardNameItalianExpert}>
                                    {content.author}
                                </span>
                            </div>
                        </div>
                    </div>
                    {!!content.date && (
                        <MDBCardText>{content.date}</MDBCardText>
                    )}
                </MDBCardBody>
            </div>
        </MDBCard>
    );
};

export default CardStories;
