import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC, useMemo, useState } from "react";
import SwiperClass from "swiper";
import styles from "../../../styles/home/Home.SmartSearch.module.scss";
import { ToursEntity } from "../../../models/home";
import { useIsXL } from "../../../utils/hooks/useWindowSizes";
import ButtonBack from "../../common/carousel/ButtonBack";
import ButtonNext from "../../common/carousel/ButtonNext";
import Carousel from "../../common/carousel/Carousel";
import NewCardTour from "../cards/cardTour/NewCardTour";

const SmartSearchCarousels: FC<{
    showOtherResults?: boolean;
    selectedCities: string[];
    tours: ToursEntity[];
}> = ({ showOtherResults, selectedCities, tours }) => {
    const isXL = useIsXL();
    const [swiperClass, setSwiperClass] = useState<SwiperClass | null>(null);

    const breackPointCarousel = {
        0: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        500: {
            slidesPerView: 1.5,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 32,
        },
        900: {
            slidesPerView: 2.5,
            spaceBetween: 32,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: showOtherResults ? 3 : 4,
            spaceBetween: 32,
        },
        1920: {
            slidesPerView: showOtherResults ? 4 : 5,
            spaceBetween: 40,
        },
    };

    const breackPointOtherCarousel = {
        0: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        1920: {
            slidesPerView: 1.25,
            spaceBetween: 32,
        },
    };

    const [swiperClassOther, setSwiperClassOther] =
        useState<SwiperClass | null>(null);

    const containsValues = (arr: string[], values: string[]) => {
        const lowerCased = arr.map((value: string) => value.toLowerCase());
        return values.every(el => lowerCased.includes(el.toLowerCase()));
    };

    const getSlide = (item: ToursEntity) => {
        return (
            <NewCardTour
                // eslint-disable-next-line no-underscore-dangle
                key={item._id}
                content={item}
                isSmall
            />
        );
    };

    const getFilteredSlide = () =>
        tours
            .filter(
                tour =>
                    containsValues(tour.destinations ?? [], selectedCities) ||
                    selectedCities.length === 0
            )
            .map(tour => getSlide(tour));

    const getNotFilteredSlide = () =>
        tours
            .filter(
                tour =>
                    !(tour.destinations ?? []).some(el =>
                        selectedCities.includes(el)
                    )
            )
            .map(tour => getSlide(tour));

    const mainContent = useMemo(() => {
        return getFilteredSlide();
    }, [selectedCities]);

    const otherContent = useMemo(() => {
        return getNotFilteredSlide();
    }, [selectedCities]);

    return (
        <>
            <MDBRow>
                <MDBCol center size={showOtherResults && !isXL ? 9 : 12}>
                    <MDBRow className="justify-content-evenly">
                        <MDBCol center size={6} lg={8}>
                            <p className={styles.carouselLabel}>Results:</p>
                        </MDBCol>
                        <MDBCol
                            center
                            size={4}
                            lg={4}
                            className="d-flex justify-content-end"
                        >
                            <ButtonBack swiperClass={swiperClass} />
                            <ButtonNext
                                className="ms-2"
                                swiperClass={swiperClass}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                {showOtherResults && !isXL && (
                    <MDBCol center size={3}>
                        <MDBRow className="justify-content-evenly">
                            <MDBCol center lg={6}>
                                <p className={styles.carouselLabel}>
                                    Other Results:
                                </p>
                            </MDBCol>
                            <MDBCol
                                center
                                size={4}
                                className="d-flex justify-content-end"
                            >
                                <ButtonBack swiperClass={swiperClassOther} />
                                <ButtonNext
                                    className="ms-2"
                                    swiperClass={swiperClassOther}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                )}
            </MDBRow>
            <MDBRow className={`${styles.carouselContainter} h-100 mt-4`}>
                <MDBCol
                    center
                    size={showOtherResults && !isXL ? 9 : 12}
                    className="h-100"
                >
                    <Carousel
                        setSwiperClass={setSwiperClass}
                        centerInsufficientSlides={false}
                        breakpoints={breackPointCarousel as never}
                        classNameCarousel="h-100"
                        containerSizes={{ size: 12 }}
                        classNameContainer="d-flex justify-content-center h-100 p-0"
                        renderContainer
                        autoHeight
                    >
                        {mainContent}
                    </Carousel>
                </MDBCol>
                {!isXL && showOtherResults && (
                    <MDBCol center size={3} className="h-100">
                        <Carousel
                            setSwiperClass={setSwiperClassOther}
                            centerInsufficientSlides={false}
                            breakpoints={breackPointOtherCarousel as never}
                            classNameContainer="d-flex justify-content-center h-100 p-0"
                            containerSizes={{ size: 12 }}
                            classNameCarousel="h-100"
                        >
                            {otherContent}
                        </Carousel>
                    </MDBCol>
                )}
            </MDBRow>
        </>
    );
};

SmartSearchCarousels.defaultProps = {
    showOtherResults: false,
};

export default SmartSearchCarousels;
