import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC } from "react";
import styles from "../../../styles/home/Home.module.scss";
import locationSvg from "../../../utils/svg-icon/location";

const HomeHeadDetailMobile: FC = () => {
    const { Italy, ItalyHand, Sun } = locationSvg;
    return (
        <MDBRow
            center
            className={`pt-5 pl-2 pr-3 displayTabletSm ${styles.homeHeadDetail}`}
        >
            <MDBCol
                className={`${styles.headRowCardContainer} bg-white rounded-9 `}
            >
                <MDBRow className="pt-4 pb-3">
                    <MDBCol size={12} md={4} className="px-4 px-lg-5 ">
                        <MDBRow>
                            <MDBCol size={3} md={4}>
                                <div className={styles.headWorldIcon}>
                                    <Italy alt="italy" />
                                </div>
                            </MDBCol>

                            <MDBCol
                                size={9}
                                md={8}
                                className="d-flex align-items-center"
                            >
                                <MDBRow>
                                    <MDBCol size={12}>
                                        <h4>Discover Italy</h4>
                                    </MDBCol>
                                    <MDBCol size={12}>
                                        <p className="pe-lg-4">
                                            We’re known for our many stereotypes
                                            but there’s so much more about Italy
                                            than that.
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>

                    <MDBCol size={12} md={4} className="px-4 px-lg-5">
                        <MDBRow>
                            <MDBCol size={3} md={4}>
                                <div className={styles.headWorldIcon}>
                                    <ItalyHand alt="italy-hand" />
                                </div>
                            </MDBCol>

                            <MDBCol
                                size={9}
                                md={8}
                                className="d-flex align-items-center"
                            >
                                <MDBRow>
                                    <MDBCol size={12}>
                                        <h4>Our Italian Expertise</h4>
                                    </MDBCol>
                                    <MDBCol size={12}>
                                        <p className="pe-lg-4">
                                            You will receive a guided tour by
                                            someone who loves, lives and
                                            breathes this Country.
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>

                    <MDBCol size={12} md={4} className="px-4 px-lg-5">
                        <MDBRow>
                            <MDBCol size={3} md={4}>
                                <div className={styles.headWorldIcon}>
                                    <Sun alt="sun" />
                                </div>
                            </MDBCol>

                            <MDBCol
                                size={9}
                                md={8}
                                className="d-flex align-items-center"
                            >
                                <MDBRow>
                                    <MDBCol size={12}>
                                        <h4>Effortless Adventure</h4>
                                    </MDBCol>
                                    <MDBCol size={12}>
                                        <p className="pe-lg-4">
                                            Just enjoy your trip, we will
                                            arrange all the scheduling. Our
                                            tours start everyday, we’re waiting
                                            for you!
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    );
};

export default HomeHeadDetailMobile;
