import {
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
} from "mdb-react-ui-kit";
import { useDisableBodyScroll } from "@/utils/hooks/useDisableBodyScroll";

import {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import { LayoutType, TourType } from "@/models/common/enums";
import {
    DestinationToExploreEntity,
    ISmartCard,
    ToursByTourTypeEntity,
} from "@/models/home";
import { randomUid } from "@/utils/randomIds";
import { useIsClient } from "@/hooks/useIsClient";
import { useIsLG } from "@/hooks/useWindowSizes";
import styles from "../../../styles/home/Home.SmartSearch.module.scss";
import SmartSearchModalHead from "./SmartSearchModalHead";
import SmartSearchModalBody from "./SmartSearchModalBody";

const SmartSeachModal: FC<{
    content: ToursByTourTypeEntity[];
    destinationToExplore: DestinationToExploreEntity[];
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    // eslint-disable-next-line sonarjs/cognitive-complexity
}> = ({ content, destinationToExplore, show, setShow }) => {
    const isClient = useIsClient();
    const isLG = useIsLG();

    useDisableBodyScroll(show);

    const regions = useMemo(() => {
        const results: { region: string; cities: string[] }[] = [];
        destinationToExplore.forEach(element => {
            const foundElement = results.findIndex(
                el => el.region === element.region
            );
            if (foundElement === -1) {
                results.push({
                    region: element.region,
                    cities: [element.city],
                });
            } else {
                results.at(foundElement)?.cities.push(element.city);
            }
        });
        return results;
    }, [destinationToExplore]);

    const [layoutType, setLayoutType] = useState<LayoutType>(LayoutType.MAIN);
    const [selectedTourType, setSelectedTourType] = useState<string>(
        TourType.ACROSSITALY
    );
    const [selectedTourTypeIndex, setSelectedTourTypeIndex] =
        useState<number>(0);
    const [selectedCity, setSelectedCity] = useState<number>(0);
    const [headSection, setHeadSection] = useState<{
        title: string;
        description: string;
        background: string;
    }>({ title: "", description: "", background: "" });

    const isMain = layoutType === LayoutType.MAIN;
    const isSubSubModal = layoutType === LayoutType.SUBSUBMODAL;

    const toggleShow = () => {
        setLayoutType(LayoutType.MAIN);
        setShow(!show);
    };

    const toggleShowSubModal = (el: string, index: number) => {
        setSelectedTourTypeIndex(index);
        setSelectedTourType(
            TourType[
                (Object.keys(TourType).find(
                    key =>
                        key.toLowerCase() ===
                        el.toLowerCase().replace(/\s+/g, "")
                ) ?? "ACROSSITALY") as keyof typeof TourType
            ]
        );
        setLayoutType(LayoutType.SUBMODAL);
    };

    const onBack = () =>
        setLayoutType(isSubSubModal ? LayoutType.SUBMODAL : LayoutType.MAIN);

    const cardContent: ISmartCard[] = useMemo(
        () =>
            content.map((el, idx) => ({
                id: randomUid(),
                value: {
                    index: idx,
                    image: el.image,
                    title: el.tourType,
                    description: el.body,
                    onClick: toggleShowSubModal,
                },
            })),
        [content]
    );

    const radioValue = useMemo(() => {
        if (selectedTourType === TourType.INTOTHEREGION) {
            return regions.map(el => {
                return {
                    id: randomUid(),
                    value: {
                        image: undefined,
                        title: el.region,
                        description: undefined,
                    },
                };
            });
        }

        return destinationToExplore.map(el => {
            return {
                id: randomUid(),
                value: {
                    image: el.image,
                    title: el.city,
                    description: el.body,
                },
            };
        });
    }, [content, selectedTourType]);

    const getBackground = () => {
        if (layoutType === LayoutType.SUBSUBMODAL) {
            return `radial-gradient(55.79% 56.95% at 100% 78.59%, #282838 43.23%, rgba(0, 0, 0, 0) 100%), linear-gradient(90.17deg, #282838 36.39%, rgba(0, 0, 0, 0) 96.17%), url(${radioValue[selectedCity].value.image}), #282838`;
        }
        if (layoutType === LayoutType.SUBMODAL) {
            return "#282838";
        }
        return "white";
    };

    useEffect(() => {
        setHeadSection({
            title: isSubSubModal
                ? `About ${radioValue[selectedCity].value.title}`
                : cardContent[selectedTourTypeIndex].value.title,
            description: isSubSubModal
                ? radioValue[selectedCity].value.description ?? ""
                : cardContent[selectedTourTypeIndex].value.description,
            background: getBackground(),
        });
    }, [layoutType, selectedCity, selectedTourTypeIndex]);

    return (
        isClient && (
            <div>
                <MDBModal show={show} setShow={setShow} tabIndex="-1">
                    <MDBModalDialog
                        size="fullscreen-xxl-down"
                        className={styles.modalSize}
                    >
                        <MDBModalContent
                            className={`${
                                styles.container
                            } d-flex justify-content-center align-items-center w-100 ${
                                layoutType === LayoutType.MAIN && isLG
                                    ? "p-0"
                                    : ""
                            }`}
                            style={{
                                background: headSection.background,
                            }}
                        >
                            <SmartSearchModalHead
                                headSection={headSection}
                                onBack={onBack}
                                isMain={isMain}
                                toggleShow={toggleShow}
                                className={
                                    layoutType === LayoutType.MAIN && isLG
                                        ? "p-3"
                                        : ""
                                }
                            />
                            <MDBModalBody
                                className={`w-100 h-100 overflow-hidden ${
                                    layoutType === LayoutType.MAIN && isLG
                                        ? "px-0"
                                        : ""
                                }`}
                            >
                                <SmartSearchModalBody
                                    setSelectedCity={setSelectedCity}
                                    selectedCity={selectedCity}
                                    content={content}
                                    destinationToExplore={destinationToExplore}
                                    layoutType={layoutType}
                                    setLayoutType={setLayoutType}
                                    selectedTourType={selectedTourType}
                                    setSelectedTourType={setSelectedTourType}
                                    setSelectedTourTypeIndex={
                                        setSelectedTourTypeIndex
                                    }
                                />
                            </MDBModalBody>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        )
    );
};

export default SmartSeachModal;
