import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import Image from "next/legacy/image";
import {
    brochuresSubTitle,
    brochuresTitle,
} from "../../../utils/mockData/homeMocks";
import SectionTitle from "../../common/SectionTitle";
import styles from "../../../styles/home/Home.Brochures.module.scss";
import mediaControlSvg from "../../../utils/svg-icon/mediaControl";
import BrochuresModal from "./BrochureModal";
import brochurePic from "../../../public/images/brochures.png";
import { ISectionTitle } from "../../../models/common/common";
import useBrochureData from "../../../utils/queries/hooks/useBrochureData";

const Brochures = () => {
    const { data: brochureData } = useBrochureData();

    const { DownloadLine } = mediaControlSvg;

    const [showModal, setShowModal] = useState(false);
    const toggleShow = () => setShowModal(!showModal);

    const title: ISectionTitle = {
        title: brochuresTitle,
        subTitle: brochuresSubTitle,
        classNameTitle: styles.textTitle,
        classNameSubTitle: styles.textSubTitle,
        titleColumnSize: 12,
        spaceBetween: 4,
        alignment: "justify-content-start text-start",
        classNameContainer: "py-3",
    };

    return (
        <>
            <MDBContainer fluid className={styles.brochuresContainer}>
                <MDBRow className="p-4 p-sm-5 h-100 justify-content-center">
                    <MDBCol
                        center
                        sm={12}
                        md={6}
                        lg={4}
                        xxl={4}
                        className="pe-4 py-4"
                    >
                        <SectionTitle sectionTitle={title} />
                        <MDBBtn
                            className={`${styles.buttonDetails} my-4 mx-auto shadow-0`}
                            color="danger"
                            onClick={toggleShow}
                            style={{
                                borderRadius: "90px",
                                gap: "12px",
                            }}
                        >
                            Download Here
                            <DownloadLine
                                className="ms-2"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                            />
                        </MDBBtn>
                    </MDBCol>
                    <MDBCol
                        center
                        sm={12}
                        md={6}
                        lg={6}
                        xxl={4}
                        className="d-block py-4"
                    >
                        <Image
                            src={brochurePic}
                            alt="Picture of the brochure"
                            sizes="320 640 750"
                            className="w-auto h-auto"
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <BrochuresModal
                brochures={brochureData!.brochures!}
                show={showModal}
                setShow={setShowModal}
            />
        </>
    );
};

export default Brochures;
