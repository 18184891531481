import {
    HeadSectionContent,
    SectionsTitleText,
} from "../../models/common/common";
import { PlayStepsInfo } from "../../models/play";
import colors from "../../styles/colors.module.scss";

export const playStepperTitle1: SectionsTitleText[] = [
    {
        text: "Trips that fit a flexible lifestyle",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const playStepperSubTitle1: SectionsTitleText[] = [
    {
        text: "Think about Italy for a second. What comes up in your mind? Well, maybe what you’re thinking about is true but",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "try thinking outside the box.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Our mission is to make you see Italy through our eyes",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "to experience a genuine and unforgettable trip.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
export const playStepperTitle2: SectionsTitleText[] = [
    {
        text: "The best Italian experts",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const playStepperSubTitle2: SectionsTitleText[] = [
    {
        text: "To be an",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "Italian Expert",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "you need two things:",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "being Italian and have an enormous love for this Country.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Thanks to the passion and dedication of our guide tours, we will take you through",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "the adventure of a lifetime in our amazing Peninsula.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const playStepperTitle3: SectionsTitleText[] = [
    {
        text: "Italy at 360°",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const playStepperSubTitle3: SectionsTitleText[] = [
    {
        text: "We crafted for you a vast option of tours that will take you basically everywhere. The picturesque Amalfi Coast, the romantic city of Venice, the plentitude of arts and history you’ll witness in both Florence and Rome ... we really have it all.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
export const playStepperTitle4: SectionsTitleText[] = [
    {
        text: "Leave it all to us",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const playStepperSubTitle4: SectionsTitleText[] = [
    {
        text: "Arranging a trip is often complicated and difficult. That’s why we’re taking out all the stress from you: just focus on your destination and enjoy your next Italian adventure.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const stepperContents: PlayStepsInfo[] = [
    {
        title: playStepperTitle1,
        subTitle: playStepperSubTitle1,
        imageUrl:
            "https://images.unsplash.com/photo-1530521954074-e64f6810b32d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    },
    {
        title: playStepperTitle2,
        subTitle: playStepperSubTitle2,
        imageUrl:
            "https://images.unsplash.com/photo-1527631746610-bca00a040d60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    },
    {
        title: playStepperTitle3,
        subTitle: playStepperSubTitle3,
        imageUrl:
            "https://images.unsplash.com/photo-1539635278303-d4002c07eae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    },
    {
        title: playStepperTitle4,
        subTitle: playStepperSubTitle4,
        imageUrl:
            "https://images.unsplash.com/photo-1516483638261-f4dbaf036963?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=686&q=80",
    },
];

export const necessitiesTitle: SectionsTitleText[] = [
    {
        text: "Choose your trip based on your necessities",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const necessitiesSubTitle: SectionsTitleText[] = [
    {
        text: "You’ll find these icons on every tour we have. We use them to immediately give you an idea on how the vacation will be. ",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
export const helpTitle: SectionsTitleText[] = [
    {
        text: "Need help?",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const helpSubTitle: SectionsTitleText[] = [
    {
        text: "Find and book a great experience.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
const headTitle: SectionsTitleText[] = [
    {
        text: "Come play with us:",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Our Italy, your esperienza.",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

const headSubTitle: SectionsTitleText[] = [
    {
        text: "The perks of Play Italy.",
        color: colors.textWhiteLight,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const headData: HeadSectionContent = {
    backgroundImageUrl:
        "https://images.unsplash.com/photo-1506012787146-f92b2d7d6d96?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
    title: headTitle,
    subTitle: headSubTitle,
};

export const titleSocialProofMarcoFogliamanzillo: SectionsTitleText[] = [
    {
        text: "Marco Fogliamanzillo",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "Founder of Iamme Ia!",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const paragraphSocialProofMarcoFogliamanzillo: SectionsTitleText[] = [
    {
        text: "Iamme Ia!",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "originated from the vision of",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "three Sorrento-based entrepreneurs ",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "who share a deep passion for travelling.  ",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "Since 2014, they have been curating unforgettable travel experiences in the captivating region of the Amalfi Coast.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Welcoming travellers from diverse backgrounds, their aim is to introduce them to all the places they cherish and love.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleSocialProofChiaraGigliotti: SectionsTitleText[] = [
    {
        text: "Chiara Gigliotti",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "CEO at Carrani Tours",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const paragraphSocialProofChiaraGigliotti: SectionsTitleText[] = [
    {
        text: "Carrani Tours, headquartered in Rome,",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "stands as a leading Italian travel enterprise. ",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "With a rich history dating back to 1925, they now present more than 70 experiences in Rome and extend their offerings to the whole of Italy. ",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "From its foundation, Carrani Tours has consistently served as the trustworthy and competent DMC for every visitor in Rome and Italy, providing unparalleled quality and expertise.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleSocialProofFrancescaMinato: SectionsTitleText[] = [
    {
        text: "Francesca Minato",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "CEO at Park View Viaggi",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const paragraphSocialProofFrancescaMinato: SectionsTitleText[] = [
    {
        text: "Park View Viaggi specializes in organizing trips to Venice and its surroundings areas,",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "drawing upon their extensive experience  in arranging both group and individual travels within Italy and also abroad.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
    {
        text: "A strong connection to the Venetian territory allows them to offer distinctive experiences that can only be crafted by those who possess a genuine understanding of the region.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const titleSocialProofGuidoBarocchi: SectionsTitleText[] = [
    {
        text: "Guido Barocchi",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "CEO at Italian Travel Group",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const paragraphSocialProofGuidoBarocchi: SectionsTitleText[] = [
    {
        text: "Italian Travel Group was born in Florence as a family-owned company back in 1923 and quickly became a renowned household name for tourists travelling in Tuscany.",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "DMC, tour and bus operator, the integrated skill offer ranges from the design of tours and activities, both shared and private, to the pure tailor made experiences and up to chauffeured and coach services.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];
