import { MDBCol, MDBRadio, MDBRow } from "mdb-react-ui-kit";
import { FC, useMemo } from "react";
import { RadioButtons } from "../../../models/home";
import styles from "../../../styles/home/Home.SmartSearch.module.scss";
import { useIsXL } from "../../../utils/hooks/useWindowSizes";
import SmartSearchFilterM from "./SmartSearchFilterM";

const RadioButtonRow: FC<{
    className?: string;
    selectedRadio: number[];
    onChange: (selectedIndex: number) => void;
    onClickBtn: () => void;
    showFilter: boolean;
    labels: RadioButtons[];
}> = ({
    className,
    onClickBtn,
    selectedRadio,
    onChange,
    labels,
    showFilter,
}) => {
    const isXL = useIsXL();
    const getLabel = (label: string) => {
        return <p className={styles.label}>{label}</p>;
    };

    const getMdbRadio = (label: RadioButtons, idx: number) => (
        <MDBRadio
            key={label.id}
            className={styles.customCheckboxChecked}
            name={label.value.title}
            id={`inlineRadio${idx}`}
            value={label.value.title}
            label={getLabel(label.value.title)}
            onClick={() => onChange(idx)}
            checked={selectedRadio.includes(idx)}
            readOnly
            inline
        />
    );

    const getRadioButtons = useMemo(
        () =>
            labels.map((label, idx) =>
                isXL ? (
                    <MDBCol size={6} md={4}>
                        {getMdbRadio(label, idx)}
                    </MDBCol>
                ) : (
                    getMdbRadio(label, idx)
                )
            ),
        [labels, selectedRadio, isXL]
    );

    const renderFilters = isXL ? (
        <SmartSearchFilterM onClickBtn={onClickBtn} className={className}>
            <MDBRow>{getRadioButtons}</MDBRow>
        </SmartSearchFilterM>
    ) : (
        <div className={className}>{getRadioButtons}</div>
    );

    return showFilter ? renderFilters : null;
};

RadioButtonRow.defaultProps = {
    className: "",
};

export default RadioButtonRow;
