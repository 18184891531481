import { FC, useMemo, useState } from "react";
import styles from "@/styles/home/Home.SmartSearch.module.scss";
import { RadioButtons, ToursEntity } from "@/models/home";
import { EBtnType, TourType } from "@/models/common/enums";
import { useIsXL } from "@/hooks/useWindowSizes";
import RadioButtonRow from "./RadioButtonRow";
import SmartSearchCarousels from "./SmartSearchCarousels";
import Btn from "../../common/Btn";
import contactsSvg from "../../../utils/svg-icon/contacts";

const SmartSearchSelection: FC<{
    cities: RadioButtons[];
    regions: { region: string; cities: string[] }[];
    selectedTourType: string;
    toursByType: ToursEntity[];
}> = ({ cities, regions, selectedTourType, toursByType }) => {
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const isXL = useIsXL();

    const { FlagLine } = contactsSvg;

    const [selectedRadioButtons, setSelectedRadioButtons] = useState<number[]>(
        []
    );

    const onChangeRadioButtonSelection = (
        selectedRadioButtonsIndex: number
    ) => {
        if (selectedRadioButtons.includes(selectedRadioButtonsIndex)) {
            setSelectedRadioButtons(exSelectedRadioButtons => {
                return exSelectedRadioButtons.filter(
                    el => el !== selectedRadioButtonsIndex
                );
            });
        } else {
            setSelectedRadioButtons(exSelectedRadioButtons => [
                ...exSelectedRadioButtons,
                selectedRadioButtonsIndex,
            ]);
        }
    };

    const selectedCities = useMemo(
        () =>
            selectedRadioButtons.map(
                selectedCity => cities[selectedCity].value.title
            ),
        [selectedRadioButtons, cities, regions]
    );

    const tours = useMemo(() => {
        if (selectedTourType === TourType.INTOTHEREGION) {
            return toursByType.map(el => {
                const tourRegions = regions
                    .filter(region =>
                        el.destinations?.some(value =>
                            region.cities.includes(value)
                        )
                    )
                    ?.map(selectedRegion => selectedRegion.region);
                return { ...el, destinations: tourRegions };
            });
        }

        return toursByType;
    }, [toursByType, regions, selectedTourType]);

    return (
        <>
            {isXL && (
                <div className="fluid text-center mb-3">
                    <Btn
                        btnType={EBtnType.TRANSPARENT}
                        onClick={() => setShowMobileFilter(true)}
                        fullWith
                        disabled={showMobileFilter}
                    >
                        <FlagLine />
                        Customize your destinations
                    </Btn>
                </div>
            )}
            <RadioButtonRow
                selectedRadio={selectedRadioButtons}
                onChange={onChangeRadioButtonSelection}
                labels={cities}
                onClickBtn={() => setShowMobileFilter(false)}
                showFilter={showMobileFilter || !isXL}
                className={styles.cardSmartSelection}
            />
            {(!showMobileFilter || !isXL) && (
                <SmartSearchCarousels
                    selectedCities={selectedCities}
                    tours={tours}
                />
            )}
        </>
    );
};

export default SmartSearchSelection;
