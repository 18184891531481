import { MDBCol } from "mdb-react-ui-kit";
import { FC } from "react";
import { ISmartCard } from "@/models/home";
import Carousel from "../../common/carousel/Carousel";
import SmartCard from "./SmartCard";

const SmartTourType: FC<{
    content: ISmartCard[];
}> = ({ content }) => {
    const cards = content.map(el => (
        <MDBCol key={el.id} className="h-100">
            <SmartCard content={el.value} />
        </MDBCol>
    ));

    return (
        <Carousel
            centerInsufficientSlides={false}
            classNameCarousel="w-100 h-100"
            breakpoints={{
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                    slidesOffsetBefore: 40,
                    slidesOffsetAfter: 40,
                },
                576: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                    slidesOffsetBefore: 40,
                    slidesOffsetAfter: 40,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                    slidesOffsetBefore: 40,
                    slidesOffsetAfter: 40,
                },
                992: {
                    slidesPerView: 2.5,
                    spaceBetween: 32,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                },
            }}
        >
            {cards}
        </Carousel>
    );
};

export default SmartTourType;
