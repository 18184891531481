import { FC } from "react";
import styles from "@/styles/home/Home.CardStories.module.scss";

const Tag: FC<{
    title: string;
    color?: string;
    className?: string;
    onClick?: () => void;
}> = ({ title, color = "red", className = "me-1", onClick = () => "" }) => {
    return (
        <div
            style={{
                backgroundColor: `${color}`,
                borderRadius: "8px",
                width: "fit-content",
            }}
            className={`p-3 ${className}`}
            onClick={onClick}
        >
            <span style={{ color: "white" }} className={styles.tagTitle}>
                {title}
            </span>
        </div>
    );
};

export default Tag;
