import Btn from "@/components/common/Btn";
import styles from "@/styles/home/Home.SmartSearch.module.scss";
import { EBtnType } from "@/models/common/enums";
import { ReactNode } from "react";
import generalSvg from "@/utils/svg-icon/general";

const SmartSearchFilterM = ({
    children,
    onClickBtn,
    className,
}: {
    children: ReactNode;
    onClickBtn: () => void;
    className?: string;
}) => {
    const { StarFilled } = generalSvg;

    return (
        <div
            className={`d-flex h-100 flex-column PIborder rounded-7 overflow-hidden p-4 m-3 align-items-center ${className}`}
        >
            <div className={`mb-3 p-0 ${styles.smartSearchMFilterDesc}`}>
                Select the destinations wich you want to travel in and
                cunstomize your esperienza italiana!
            </div>
            <div
                className={`m-0 p-0 ${styles.radioButtonMContainer} flex-fill w-100`}
            >
                {children}
            </div>
            <div className="mt-3">
                <Btn
                    btnType={EBtnType.TRANSPARENT}
                    onClick={onClickBtn}
                    fullWith
                >
                    <span className="me-2">
                        <StarFilled />
                    </span>
                    See the tours
                </Btn>
            </div>
        </div>
    );
};

export default SmartSearchFilterM;
