import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardImage,
} from "mdb-react-ui-kit";
import { FC } from "react";
import styles from "../../../styles/home/Home.CardBrochures.module.scss";
import { CardBrochuresContent, EBrochuresType } from "../../../models/home";
import SectionTitle from "../../common/SectionTitle";
import { ISectionTitle } from "../../../models/common/common";

const CardBrochures: FC<{
    content: CardBrochuresContent;
    type: EBrochuresType;
    onDownload: (type: EBrochuresType, title: string) => void;
}> = ({ content, type, onDownload }) => {
    const title: ISectionTitle = {
        title: content.title,
        subTitle: content.subTitle,
        classNameTitle: styles.textTitle,
        classNameSubTitle: styles.textSubTitle,
        titleColumnSize: 12,
        spaceBetween: 2,
        alignment: "justify-content-start text-center",
    };

    return (
        <MDBCard className={`${styles.cardBody}`}>
            <MDBCardHeader className="border-0 d-flex justify-content-center">
                <MDBCardImage
                    src={
                        content.imagesrc
                            ? `${content.imagesrc}`
                            : "https://images.unsplash.com/photo-1593723117578-c3f29d0b62c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
                    }
                    fluid
                    alt="..."
                    className="rounded-9"
                    style={{
                        height: "164px",
                        width: "198px",
                        objectFit: "cover",
                        marginTop: "2rem",
                    }}
                />
            </MDBCardHeader>

            <MDBCardBody
                className="d-flex flex-column justify-content-between pt-0"
                style={{ marginBottom: "2rem" }}
            >
                <SectionTitle sectionTitle={title} />
                <MDBBtn
                    className={`${styles.buttonDetails}  mx-auto shadow-0`}
                    style={{ bottom: "0" }}
                    color="danger"
                    onClick={() =>
                        onDownload(
                            type,
                            content.title.reduce(
                                (acc: string, value) => acc.concat(value.text),
                                ""
                            )
                        )
                    }
                >
                    Download
                </MDBBtn>
            </MDBCardBody>
        </MDBCard>
    );
};

export default CardBrochures;
