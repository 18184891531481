import { useQuery } from "@tanstack/react-query";
import { getItalianStoriesData } from "../index";

const useGeneralItalianStoriesData = () => {
    return useQuery({
        queryKey: ["italian-stories-data"],
        queryFn: () => getItalianStoriesData(),
        refetchOnWindowFocus: false,
    });
};

export default useGeneralItalianStoriesData;
