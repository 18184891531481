import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { FC, useState } from "react";
import generalSvg from "@/utils/svg-icon/general";
import { useIsSM } from "@/utils/hooks/useWindowSizes";
import { useShouldRenderArrayFC } from "@/hooks/useShouldRenderArrayFC";
import HomeHeadDetailMobile from "./HomeHeadDetailMobile";
import HomeHeadDetailDesktop from "./HomeHeadDetailDesktop";
import SmartSeachModal from "../smartSearch/SmartSearchModal";
import {
    DestinationToExploreEntity,
    ToursByTourTypeEntity,
} from "../../../models/home";
import styles from "../../../styles/home/Home.module.scss";

const HomeHead: FC<{
    smartSearchContent?: ToursByTourTypeEntity[];
    destinationToExplore?: DestinationToExploreEntity[] | null;
    headImage: string;
}> = ({ smartSearchContent, destinationToExplore, headImage }) => {
    const { Search } = generalSvg;
    const [showModal, setShowModal] = useState(false);
    const isSM = useIsSM();

    const handleClick = () => {
        setShowModal(prev => !prev);
    };

    const destinationExist = useShouldRenderArrayFC(destinationToExplore);
    const smartSearchContentExist = useShouldRenderArrayFC(smartSearchContent);

    return (
        <MDBContainer fluid className={styles.homeHead}>
            <div
                className={styles.homeHeadBgImg}
                style={{ backgroundImage: `url(${headImage})` }}
            >
                <div className={styles.homeHeadTitle}>
                    <div className="m-0">
                        <h1 className="mb-2">Play Italy</h1>
                        <h2>Book the experience of a lifetime.</h2>
                    </div>
                    <div className={styles.headButtonContainer}>
                        <MDBBtn
                            outline
                            rounded
                            className={styles.homeHeadButtonCTA}
                            onClick={handleClick}
                        >
                            Discover our Italian Tours
                            <div className={styles.homeHeadIconSearch}>
                                <Search alt="search-icon" />
                            </div>
                        </MDBBtn>
                    </div>
                    {isSM && <HomeHeadDetailMobile />}
                </div>
            </div>
            <div
                className={`${styles.headLowerContainer} d-flex justify-content-center`}
            >
                {!isSM && (
                    <div className={styles.headLowerContentContainer}>
                        <HomeHeadDetailDesktop />
                    </div>
                )}
            </div>
            {destinationExist && smartSearchContentExist && (
                <SmartSeachModal
                    content={smartSearchContent!}
                    show={showModal}
                    setShow={setShowModal}
                    destinationToExplore={destinationToExplore!}
                />
            )}
        </MDBContainer>
    );
};

export default HomeHead;
