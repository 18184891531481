import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import useOnScreen from "@/utils/hooks/useOnScreen";
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager from "react-gtm-module";
import {
    videoComplete,
    videoProgress,
    videoStart,
} from "@/utils/analytics/tagmanager";
import styles from "../../styles/home/Home.OurManifest.module.scss";
import mediaControlSvg from "../../utils/svg-icon/mediaControl";

type sendEventProp = {
    percent10: boolean;
    percent25: boolean;
    percent50: boolean;
    percent75: boolean;
};

const OurManifest: FC<{ previewImage: string; video: string }> = ({
    previewImage,
    video,
}) => {
    const { Play } = mediaControlSvg;

    const [showVideoFrame, setShowVideoFrame] = useState(false);

    const videoEl = useRef<HTMLVideoElement>(null);
    const divEl = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line camelcase
    const video_title = "our manifesto";
    const [sendEvent, setSendEvent] = useState<sendEventProp>({
        percent10: false,
        percent25: false,
        percent50: false,
        percent75: false,
    });

    useEffect(() => {
        const onStartVideo = () => {
            TagManager.dataLayer(videoStart(video, video_title));
        };

        if (showVideoFrame) onStartVideo();
    }, [showVideoFrame, video]);

    const onEndAction = () => {
        TagManager.dataLayer(videoComplete(video, video_title));
    };

    const onPauseAction = () => {
        const durata = videoEl.current?.duration;
        const currentTime = videoEl.current?.currentTime;
        const percentuale = (100 / durata!) * currentTime!;

        const percentageBody = (percent: number) => {
            return TagManager.dataLayer(
                videoProgress(video, video_title, percent)
            );
        };

        if (percentuale >= 10 && sendEvent.percent10 === false) {
            percentageBody(percentuale);
            setSendEvent({ ...sendEvent, percent10: true });
        }
        if (percentuale >= 25 && sendEvent.percent25 === false) {
            percentageBody(percentuale);
            setSendEvent({ ...sendEvent, percent25: true });
        }
        if (percentuale >= 50 && sendEvent.percent50 === false) {
            percentageBody(percentuale);
            setSendEvent({ ...sendEvent, percent50: true });
        }

        if (percentuale >= 75 && sendEvent.percent75 === false) {
            percentageBody(percentuale);
            setSendEvent({ ...sendEvent, percent75: true });
        }
    };

    const isOnScreen = useOnScreen(divEl as RefObject<HTMLElement>);
    useEffect(() => {
        if (!isOnScreen) {
            videoEl.current?.pause();
        }
    }, [isOnScreen]);

    return (
        <div ref={divEl}>
            <MDBContainer
                fluid
                className={`${styles.container} px-2 overflow-sm-hidden`}
            >
                <MDBCol className="d-flex align-items-center h-100">
                    <MDBRow className={` ${styles.row} flex-fill`}>
                        {!showVideoFrame && (
                            <div className={styles.scrollContainer}>
                                <p className={styles.textCover}>
                                    our manifesto
                                </p>
                            </div>
                        )}
                        <div
                            className={` ${styles.videoContainer} bg-image d-flex align-items-center justify-content-center px-0`}
                        >
                            {showVideoFrame && (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video
                                    className="w-100 h-100"
                                    controls
                                    autoPlay
                                    title="review video"
                                    ref={videoEl}
                                    onEnded={onEndAction}
                                    onPause={onPauseAction}
                                >
                                    <source src={video} />
                                </video>
                            )}
                            {!showVideoFrame && (
                                <>
                                    <img
                                        src={previewImage}
                                        className={`${styles.imageCover} img-fluid w-100 h-100`}
                                        id="image-manifest"
                                        alt="Sample"
                                    />
                                    <div
                                        className={`${styles.maskOverlay} mask`}
                                    >
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                            <MDBBtn
                                                className={`${styles.playButton} p-4 shadow-0`}
                                                onClick={() =>
                                                    setShowVideoFrame(
                                                        !showVideoFrame
                                                    )
                                                }
                                            >
                                                <Play />
                                            </MDBBtn>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </MDBRow>
                </MDBCol>
            </MDBContainer>
        </div>
    );
};

export default OurManifest;
