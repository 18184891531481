import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC } from "react";
import styles from "../../../styles/home/Home.module.scss";
import locationSvg from "../../../utils/svg-icon/location";

const HomeHeadDetailDesktop: FC = () => {
    const { Italy, ItalyHand, Sun } = locationSvg;
    return (
        <MDBRow center>
            <MDBCol
                className={`${styles.headRowCardContainer} bg-white rounded-9`}
            >
                <MDBRow className={`${styles.headCardContainer} py-4`}>
                    <MDBCol size={12} md={4} className="px-4 px-lg-5">
                        <div className={styles.headWorldIcon}>
                            <Italy alt="italy" />
                        </div>
                        <h4>Discover Italy</h4>
                        <p className="pe-lg-4">
                            We’re known for our many stereotypes but there’s so
                            much more about Italy than that.
                        </p>
                    </MDBCol>

                    <MDBCol size={12} md={4} className="px-4 px-lg-5">
                        <div className={styles.headWorldIcon}>
                            <ItalyHand alt="italy-hand" />
                        </div>
                        <h4>Our Italian Expertise</h4>
                        <p className="pe-lg-4">
                            You will receive a guided tour by someone who loves,
                            lives and breathes this Country.
                        </p>
                    </MDBCol>
                    <MDBCol size={12} md={4} className="px-4 px-lg-5">
                        <div className={styles.headWorldIcon}>
                            <Sun alt="sun" />
                        </div>
                        <h4>Effortless Adventure</h4>
                        <p className="pe-lg-4">
                            Just enjoy your trip, we will arrange all the
                            scheduling. Our tours start everyday, we’re waiting
                            for you!
                        </p>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    );
};

export default HomeHeadDetailDesktop;
