import { useQuery } from "@tanstack/react-query";
import { getHomeData } from "../index";

const useHomeData = () => {
    return useQuery({
        queryKey: ["home"],
        queryFn: () => getHomeData(),
        refetchOnWindowFocus: false,
    });
};

export default useHomeData;
