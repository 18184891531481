import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import { FC } from "react";
import _ from "lodash";
import { slugify } from "@/utils/searchUtils";
import styles from "../../styles/home/Home.Italianstories.module.scss";
import {
    italianStoriesSubTitle,
    italianStoriesTitle,
} from "../../utils/mockData/homeMocks";
import SectionCarouselLayout from "../common/SectionCarouselLayout";
import CardStories from "./cards/CardStories";
import {
    SectionsTitleText,
    TBreackpointsCarousel,
} from "../../models/common/common";
import { ItalianStoriesResponseTo } from "../../models/italianStory";

const ItalianStories: FC<{
    sectionTitle?: SectionsTitleText[];
    sectionSubTitle?: SectionsTitleText[];
    content: ItalianStoriesResponseTo[];
}> = ({ content, sectionTitle = undefined, sectionSubTitle = undefined }) => {
    const router = useRouter();
    const loadMorePath = "/italian-stories";

    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1.1,
            height: 533,
            spaceBetween: 10,
        },
        576: {
            slidesPerView: 1.1,
            height: 533,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 2,
            height: 533,
            spaceBetween: 12,
        },
        992: {
            slidesPerView: 3,
            height: 533,
            spaceBetween: 12,
        },
        1200: {
            slidesPerView: 3,
            height: 533,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 568,
        },
        1600: {
            slidesPerView: 3,
            spaceBetween: 32,
            height: 568,
        },
        1920: {
            slidesPerView: 4,
            spaceBetween: 32,
            height: 568,
        },
    };

    return (
        <MDBContainer fluid className={`${styles.container} py-5 px-0`}>
            <SectionCarouselLayout
                visibleSlides={3}
                sectionTitle={sectionTitle ?? italianStoriesTitle}
                sectionSubTitle={sectionSubTitle ?? italianStoriesSubTitle}
                breakpointsCarousel={breackPointCarousel}
                isCarouselContained
            >
                {_.orderBy(content, ["createdAt"], ["desc"]).map(el => (
                    /* eslint-disable no-underscore-dangle */
                    <CardStories
                        key={el._id}
                        content={el}
                        onClick={() => {
                            router.push({
                                pathname: "/italian-stories/[slug]",
                                query: {
                                    slug: slugify(el.title),
                                },
                            });
                        }}
                    />
                    /* eslint-enable no-underscore-dangle */
                ))}
            </SectionCarouselLayout>

            <MDBRow className="d-flex justify-content-center">
                <MDBCol size={10} className="d-flex px-3">
                    <MDBBtn
                        className={`${styles.buttonLoad} shadow-0`}
                        onClick={() => {
                            router.push(loadMorePath);
                        }}
                    >
                        Load More
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default ItalianStories;
