import {
    MDBBtn,
    MDBCol,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader,
    MDBRow,
} from "mdb-react-ui-kit";
import { FC, useEffect, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager from "react-gtm-module";
import { fileDownload } from "@/utils/analytics/tagmanager";
import CardBrochures from "../cards/CardBrochures";
import userInterfaceSvg from "../../../utils/svg-icon/userInterface";
import { useIsMD } from "../../../utils/hooks/useWindowSizes";
import styles from "../../../styles/home/Home.Brochures.module.scss";
import {
    card1Content,
    card2Content,
    card3Content,
    card4Content,
} from "../../../utils/mockData/homeMocks";
import Carousel from "../../common/carousel/Carousel";
import { TBreackpointsCarousel } from "../../../models/common/common";
import { BrochuresEntity, EBrochuresType } from "../../../models/home";

const BrochuresModal: FC<{
    show: boolean;
    setShow: (el: boolean) => void;
    brochures: BrochuresEntity[];
}> = ({ brochures, show, setShow }) => {
    const { CloseLine } = userInterfaceSvg;

    const toggleShow = () => setShow(!show);
    const [render, setRender] = useState(false);

    useEffect(() => setRender(true), []);

    const router = useRouter();

    const downloadFileAtURL = (url: string | undefined, fileName: string) => {
        if (url) {
            axios
                .get(url, { responseType: "arraybuffer" })
                .then(blob => {
                    const newBlob = new Blob([blob.data], {
                        type: "application/pdf",
                    });
                    const blobURL = window.URL.createObjectURL(newBlob);
                    const aTag = document.createElement("a");
                    aTag.href = blobURL;
                    aTag.setAttribute("download", fileName);
                    document.body.appendChild(aTag);
                    aTag.click();
                    aTag.remove();

                    TagManager.dataLayer(fileDownload(fileName, router.asPath));
                })
                .catch(error => console.log(error));
        }
    };

    const handleDownload = (type: EBrochuresType, title: string) => {
        if (type === EBrochuresType.ALL) {
            const brochureParams = brochures.filter(
                item => item.type === "All the Tour"
            );
            downloadFileAtURL(
                brochureParams.at(0)?.brochure,
                brochureParams.at(0)?.type as string
            );
        } else {
            downloadFileAtURL(
                brochures.find(
                    el =>
                        el.type.toLowerCase().replace(/\s/g, "") ===
                        title.toLowerCase().replace(/\s/g, "")
                )?.brochure,
                title
            );
        }
    };

    const cards = [
        {
            id: EBrochuresType.ALL,
            value: (
                <CardBrochures
                    content={card1Content}
                    type={EBrochuresType.ALL}
                    onDownload={handleDownload}
                />
            ),
        },
        {
            id: EBrochuresType.ACROSSITALY,
            value: (
                <CardBrochures
                    content={card2Content}
                    type={EBrochuresType.ACROSSITALY}
                    onDownload={handleDownload}
                />
            ),
        },
        {
            id: EBrochuresType.INTOTHEREGION,
            value: (
                <CardBrochures
                    content={card3Content}
                    type={EBrochuresType.INTOTHEREGION}
                    onDownload={handleDownload}
                />
            ),
        },
        {
            id: EBrochuresType.DOWNTHECITY,
            value: (
                <CardBrochures
                    content={card4Content}
                    type={EBrochuresType.DOWNTHECITY}
                    onDownload={handleDownload}
                />
            ),
        },
    ];

    const isMd = useIsMD();

    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1,
            spaceBetween: 32,
        },
        576: {
            slidesPerView: 1.2,
            spaceBetween: 32,
        },
        768: {
            slidesPerView: 2.2,
            spaceBetween: 32,
        },
        992: {
            slidesPerView: 2.5,
            spaceBetween: 32,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 32,
        },
        1600: {
            slidesPerView: 4,
            spaceBetween: 32,
        },
    };

    return render ? (
        <MDBModal show={show} setShow={setShow} tabIndex="-1" staticBackdrop>
            <MDBModalDialog
                size="xl"
                className={`${styles.dialog} bg-image p-lg-5 rounded-9 p-0`}
                style={{
                    backgroundImage:
                        "url('https://images.unsplash.com/photo-1478860409698-8707f313ee8b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80')",
                }}
            >
                <MDBModalContent
                    className={`${styles.overlayMask} mask rounded-9 d-flex align-items-center p-0`}
                >
                    <MDBModalHeader className="border border-0 d-flex justify-content-end  mt-5 p-0">
                        <MDBBtn
                            className={`${styles.buttonClose} position-absolute d-flex justify-content-center shadow-0`}
                            tag="a"
                            floating
                            onClick={toggleShow}
                        >
                            <CloseLine className="align-self-center" />
                        </MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody
                        className={`d-flex align-items-center ${
                            isMd && "w-100 p-0"
                        } ${styles.modalBody}`}
                    >
                        <MDBRow
                            className={`p-0 ${isMd && "h-100 w-100 flex-fill"}`}
                        >
                            {!isMd &&
                                cards.map(card => (
                                    <MDBCol
                                        className="mx-4 mx-lg-0"
                                        key={card.id}
                                        size={4}
                                        lg={3}
                                    >
                                        {card.value}
                                    </MDBCol>
                                ))}
                            {isMd && (
                                <MDBCol
                                    center
                                    size={12}
                                    className="h-100 d-flex justify-content-center align-items-center h-100 mx-3"
                                >
                                    <Carousel
                                        visibleSlides={2.2}
                                        breakpoints={breackPointCarousel}
                                    >
                                        {cards.map(el => (
                                            <div
                                                key={el.id}
                                                className={` ${styles.slideContainer}`}
                                            >
                                                {el.value}
                                            </div>
                                        ))}
                                    </Carousel>
                                </MDBCol>
                            )}
                        </MDBRow>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : null;
};

export default BrochuresModal;
